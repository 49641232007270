<template>
  <div>
    <div class="row">
      <div class="col-xl-3">
        <!--begin::Stats Widget 29-->
        <div
          class="card card-custom bgi-no-repeat card-stretch gutter-b"
          style="
            background-position: right top;
            background-size: 30% auto;
            background-image: url(/media/svg/shapes/abstract-1.svg);
          "
        >
          <!--begin::Body-->
          <div class="card-body">
            <span class="svg-icon svg-icon-2x svg-icon-primary">
              <span class="svg-icon svg-icon-2x">
                <inline-svg src="/media/svg/icons/Shopping/Wallet.svg" />
              </span>
            </span>
            <span
              v-if="cash"
              class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block"
            >
              ฿
              {{
                cash.baht.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })
              }}
            </span>
            <span class="font-weight-bold text-muted font-size-sm">
              เงินในกระเป๋า
            </span>
          </div>
          <!--end::Body-->
        </div>
        <!--end::Stats Widget 29-->
      </div>
    </div>
    <b-overlay :show="loading" rounded="sm">
      <b-form class="form" @submit.stop.prevent="do_save">
        <div class="card card-custom card-stretch">
          <div class="card-body">
            <h3 class="text-dark-75 font-weight-bold mb-8">โอนเงินไปจีน</h3>
            <div class="row mt-4">
              <div class="col-lg-8">
                <b-form-group
                  label-cols="4"
                  label-cols-sm="3"
                  label-cols-lg="3"
                  label-align="right"
                  content-cols="8"
                  content-cols-sm="7"
                  content-cols-lg="7"
                  content-cols-xl="6"
                  description="กรอกบัญชีธนาคาร"
                  label="รายละเอียดธนาคาร"
                  label-for="input-bank"
                >
                  <b-form-textarea
                    id="input-bank"
                    v-model="form.bank_name"
                    rows="4"
                    placeholder="ชื่อบัญชี..."
                  ></b-form-textarea>
                </b-form-group>
                <b-form-group
                  label-cols="4"
                  label-cols-sm="3"
                  label-cols-lg="3"
                  label-align="right"
                  label="ยอดเงิน"
                  label-for="input-amount"
                  content-cols="8"
                  content-cols-sm="7"
                  content-cols-lg="7"
                  content-cols-xl="6"
                >
                  <b-form-input
                    id="input-amount"
                    v-model="form.amount"
                    type="number"
                    step="0.01"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label-cols="4"
                  label-cols-sm="3"
                  label-cols-lg="3"
                  label-align="right"
                  label="รูปบัญชีธนาคาร"
                  label-for="input-amount"
                  content-cols="8"
                  content-cols-sm="7"
                  content-cols-lg="7"
                  content-cols-xl="6"
                >
                  <div class="uppy">
                    <div class="uppy-wrapper">
                      <div class="uppy-Root uppy-FileInput-container">
                        <input
                          class="uppy-FileInput-input uppy-input-control"
                          ref="fileInput"
                          type="file"
                          name="files[]"
                          accept="image/*"
                          multiple=""
                          @input="pickFile"
                          id="file"
                          style=""
                        />
                        <label
                          class="uppy-input-label btn btn-light-primary btn-sm btn-bold"
                          for="file"
                        >
                          เลือก รูป
                        </label>
                      </div>
                    </div>
                    <div class="uppy-list">
                      <div class="uppy-list-item" v-if="previewImage">
                        <div
                          v-viewer
                          class="border symbol symbol-60 flex-shrink-0 mr-4"
                        >
                          <img
                            style="object-fit: contain"
                            :src="previewImage"
                            height="200"
                            width="200"
                          />
                        </div>
                        <div class="uppy-list-label">
                          {{ file.name }}
                        </div>
                        <span class="uppy-list-remove" @click="removeFile()">
                          <i class="flaticon2-cancel-music"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <span class="form-text text-muted">
                    อัพโหลดไฟล์ไม่เกิน 20MB.
                  </span>
                </b-form-group>
                <b-form-group
                  v-if="cash"
                  label-cols="4"
                  label-cols-sm="3"
                  label-cols-lg="3"
                  label-align="right"
                  label=""
                  content-cols="8"
                  content-cols-sm="7"
                  content-cols-lg="7"
                  content-cols-xl="6"
                >
                  <div class="dashed"></div>
                  <div class="my-4 text-right fs-4">
                    เรทเงิน (หยวน) :
                    {{ cash.rateAlipay }}
                  </div>
                  <div class="bg-light p-2 mb-4 rounded text-right fs-2">
                    ยอดรวม :
                    {{ " " }}
                    {{
                      total.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })
                    }}
                    บาท
                  </div>
                </b-form-group>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-lg-10 ml-lg-auto">
                <button
                  class="btn btn-success mr-2"
                  type="submit"
                  style="width: 144px"
                >
                  <span class="svg-icon svg-icon-md">
                    <inline-svg src="/media/svg/icons/General/Save.svg" />
                  </span>
                  บันทึก
                </button>
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </b-overlay>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { CREATE_TRANSFER } from "@/graphql/payment";
import { GET_CASH } from "@/graphql/profile";
import { multipy } from "@/config";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";

export default {
  mixins: [validationMixin],
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `โอนเงินไปจีน`, route: "/payments" },
      { title: `สร้างรายการโอนเงินไปจีน` }
    ]);
  },
  data() {
    return {
      previewImage: null,
      valid: false,
      loading: false,
      form: {
        amount: 0,
        file: null,
        bank_name: ""
      },
      rules: {
        required: value => !!value || "กรุณากรอกข้อมูล"
      }
    };
  },
  validations: {
    form: {
      bank_name: {},
      amount: {
        required
      },
      file: {}
    }
  },
  computed: {
    total() {
      const total = multipy(this.cash.rateAlipay, this.form.amount);
      return total;
    }
  },
  methods: {
    async do_save() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const confirm = await Swal.fire({
        title: "ยืนยันการโอนเงินไปจีน ?",
        icon: "question",
        html: `กรุณาตรวจสอบ บัญชีธนาคาร ก่อนบันทึกทุกครั้ง`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }

      const input = {
        amount: Number(this.$v.form.amount.$model),
        bank_name: this.$v.form.bank_name.$model,
        file: this.file
      };

      this.loading = true;

      const {
        data: { createPayment }
      } = await this.$apollo.mutate({
        mutation: CREATE_TRANSFER,
        variables: {
          input
        }
      });
      this.loading = false;
      if (!createPayment.isSuccess) {
        this.$message.error(
          createPayment.message ||
            (createPayment.error_list &&
              createPayment.error_list.map(x => x.message).join())
        );
      } else {
        this.$message.success(createPayment.message);
        this.$router.push({ name: "payment_list" });
      }
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        if (
          file[0]["type"] !== "image/jpeg" &&
          file[0]["type"] !== "image/png"
        ) {
          Swal.fire({
            title: "",
            text: "แนบไฟล์ JPG/PNG เท่านั้น",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
          return;
        }
        let reader = new FileReader();
        reader.onload = e => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.file = file[0];
        this.fileValid = !!this.file;
        this.$emit("input", file[0]);
      }
    },
    removeFile() {
      this.file = null;
      this.previewImage = null;
    }
  },
  apollo: {
    cash: {
      query: GET_CASH
    }
  }
};
</script>
